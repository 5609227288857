<template>
  <div>
    <zapier-full-experience
      :clientId.prop='"EnxAdICy51zFzoYwv6y0doqPxitnaXLE9MMjjwKx"'
      :theme.prop='"light"'
      :introCopyDisplay.prop='"show"'
      :appSearchBarDisplay.prop='"show"'
    ></zapier-full-experience>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>
