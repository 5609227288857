<template>
  <div class="NotificationsZapier">
    <div class="top">
      <div class="title">Zapier Notification</div>
    </div>
    <div class="card">
      <div class="card-body">
        <zapier />
      </div>
    </div>
  </div>
</template>

<script>
import Zapier from '@/components/Monitor/Notifications/Zapier/Zapier.vue'

export default {
  components: {
    Zapier
  },

  metaInfo: {
    title: 'Zapier Notifications'
  }
}
</script>

<style lang="scss" scoped>
.NotificationsZapier {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      color: #444;
    }
  }
}
</style>
